import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/infrastructure/store';
import '@/App.container';
import pgaDiContainer from '@/App.container';
import { KeyValue } from '../../types';
import IPokemonService, { Pokeomon } from '../../services/iPokemonService';
import PokemonService from '@/services/PokemonService';

export interface OfferServiceState {
    TipoComponente: Array<KeyValue>;
    BusinessLine: Array<KeyValue>;
    PokemonList: Array<Pokeomon>;
    TechArea: Array<KeyValue>;
    TipologiaFornitura: Array<KeyValue>;
    TipologiaOfferta: Array<KeyValue>;
    TipologiaPostsale: Array<KeyValue>;
    Vincoli: Array<KeyValue>;
}

export interface PokemonList {
    Name: Pokeomon;
}

@Module({ namespaced: true, dynamic: true, store, name: 'OfferServiceModule', })
class OfferServiceStore extends VuexModule implements OfferServiceState {

    pokemonService: IPokemonService = pgaDiContainer.get<IPokemonService>(PokemonService);

    PokemonList: Array<Pokeomon> = [];

    TipoComponente: KeyValue[] = [
        { value: "01", text: "ACQUISTO PRODOTTI" } as KeyValue,
        { value: "02", text: "ACQUISTO SERVIZI" } as KeyValue,
        { value: "03", text: "COSTI TRASFERTA" } as KeyValue,
        { value: "04", text: "COSTI SICUREZZA" } as KeyValue,
        { value: "05", text: "ALTRI" } as KeyValue,
    ];

    BusinessLine: KeyValue[] = [
        { value: "01", text: "Modern  WorkPlace - SYS" } as KeyValue,
        { value: "02", text: "Cloud Adoption - SYS" } as KeyValue,
        { value: "03", text: "Security & Compliance - CONS" } as KeyValue,
        { value: "04", text: "Evolve Your Infrastructure - SYS" } as KeyValue,
        { value: "05", text: "Software Development - APPL" } as KeyValue,
        { value: "06", text: "Data & AI - APPL" } as KeyValue,
    ];

    TechArea: KeyValue[] = [
        { value: "01", text: "Messaging", partentcode: "Modern  WorkPlace - SYS" } as KeyValue,
        { value: "02", text: "Comm & Coll", partentcode: "Modern  WorkPlace - SYS" } as KeyValue,
        { value: "03", text: "Public Cloud", partentcode: "Cloud Adoption - SYS" } as KeyValue,
        { value: "04", text: "Hybrid Cloud", partentcode: "Cloud Adoption - SYS" } as KeyValue,
        { value: "05", text: "Business Continuity & Disaster recovery", partentcode: "Cloud Adoption - SYS" } as KeyValue,
        { value: "06", text: "GDPR & AGID", partentcode: "Security & Compliance - CONS" } as KeyValue,
        { value: "07", text: "Software Asset Management", partentcode: "Security & Compliance - CONS" } as KeyValue,
        { value: "08", text: "System Management", partentcode: "Security & Compliance - CONS" } as KeyValue,
        { value: "09", text: "Security", partentcode: "Security & Compliance - CONS" } as KeyValue,
        { value: "10", text: "Platform Migration", partentcode: "Evolve Your Infrastructure - SYS" } as KeyValue,
        { value: "11", text: "Infrastructure Management", partentcode: "Evolve Your Infrastructure - SYS" } as KeyValue,
        { value: "12", text: "Operation Management", partentcode: "Evolve Your Infrastructure - SYS" } as KeyValue,
        { value: "13", text: "Portals & Collaboration", partentcode: "Software Development - APPL" } as KeyValue,
        { value: "14", text: "Cloud & Mobile Dev", partentcode: "Software Development - APPL" } as KeyValue,
        { value: "15", text: "Products", partentcode: "Software Development - APPL" } as KeyValue,
        { value: "16", text: "Devops", partentcode: "Software Development - APPL" } as KeyValue,
        { value: "17", text: "OnPrem Dev", partentcode: "Software Development - APPL" } as KeyValue,
        { value: "18", text: "Business Intelligence", partentcode: "Data & AI - APPL" } as KeyValue,
        { value: "19", text: "Cognitive Services", partentcode: "Data & AI - APPL" } as KeyValue,
        { value: "19", text: "Machine Learning", partentcode: "Data & AI - APPL" } as KeyValue,
    ];

    TipologiaFornitura: KeyValue[] = [
        { value: "01", text: "Rivendita" } as KeyValue,
        { value: "02", text: "Commesse di Sviluppo Software" } as KeyValue,
        { value: "03", text: "PublCommesse di progettazione e realizzazione di sitstemi ICTic Cloud" } as KeyValue,
        { value: "04", text: "Consulenza a progetto" } as KeyValue,
        { value: "05", text: "Servizi di manutenzione" } as KeyValue,
        { value: "06", text: "GDServizi T&M" } as KeyValue,
        { value: "07", text: "Managed Services" } as KeyValue,
        { value: "08", text: "Corsi di formazione destinati a clienti" } as KeyValue,
        { value: "09", text: "General Services" } as KeyValue,
        { value: "10", text: "Prodotti a catalogo" } as KeyValue,
        { value: "11", text: "Soluzioni a catalogo" } as KeyValue,
        { value: "12", text: "Fee" } as KeyValue,
    ];

    TipologiaOfferta: KeyValue[] = [
        { value: "01", text: "New" } as KeyValue,
        { value: "02", text: "Renew" } as KeyValue,
    ];

    TipologiaPostsale: KeyValue[] = [
        { value: "01", text: "Manutenzione" } as KeyValue,
        { value: "02", text: "Garanzia" } as KeyValue,
        { value: "03", text: "Assistenza" } as KeyValue,
        { value: "04", text: "Altri" } as KeyValue,
    ];

    Mol: KeyValue[] = [
        { value: "01", text: "Si" } as KeyValue,
        { value: "02", text: "Inferiore" } as KeyValue,
        { value: "03", text: "Approvazione DG" } as KeyValue,
    ];

    Vincoli: KeyValue[] = [
        { value: "01", text: "Non concorrenza" } as KeyValue,
        { value: "02", text: "Non assunzione del personale" } as KeyValue,
        { value: "03", text: "Nomina riferimenti commessa" } as KeyValue,
        { value: "04", text: "Commerciale (ACOM)" } as KeyValue,
        { value: "05", text: "Tecnico (PM)" } as KeyValue,
        { value: "06", text: "Documentazione di accesso ai locali del Cliente" } as KeyValue,
        { value: "07", text: "Dichiarazione responsabilità dipendenti" } as KeyValue,
        { value: "08", text: "Nomina responsabilità trattamento dati personali" } as KeyValue,
        { value: "09", text: "Titolare" } as KeyValue,
        { value: "10", text: "Responsabile" } as KeyValue,
        { value: "11", text: "Tracciabilità dei flussi finanziari" } as KeyValue,
        { value: "12", text: "Divieto di subappalto" } as KeyValue,
        { value: "13", text: "Divieto di cessione del contratto" } as KeyValue,
        { value: "14", text: "Divieto di cessione del credito" } as KeyValue,
        { value: "15", text: "Cauzione" } as KeyValue,
        { value: "16", text: "Penali" } as KeyValue,
        { value: "17", text: "Regolarità retributiva, contributiva, previdenziale, assicurativa e fiscale" } as KeyValue,
    ];

    SubVincoli: KeyValue[] = [
        { value: "01", text: "LUL" } as KeyValue,
        { value: "02", text: "UNILAV" } as KeyValue,
        { value: "03", text: "DURK" } as KeyValue,
        { value: "04", text: "ALTRO" } as KeyValue,
    ];

    ReferenzaCliente: KeyValue[] = [
        { value: "01", text: "NO" } as KeyValue,
        { value: "02", text: "CASE STUDY" } as KeyValue,
        { value: "03", text: "CITAZIONI" } as KeyValue,
        { value: "04", text: "INTERVISTA" } as KeyValue,
        { value: "05", text: "ALTRO" } as KeyValue,
    ];

    DocumentiMarketing: KeyValue[] = [
        { value: "01", text: "NO" } as KeyValue,
        { value: "02", text: "ARCHITETTURA" } as KeyValue,
        { value: "03", text: "PRESENTAZIONE PPT" } as KeyValue,
        { value: "04", text: "WEBINAR" } as KeyValue,
        { value: "05", text: "ALTRO" } as KeyValue,
    ];

    TipologiaVerbale: KeyValue[] = [
        { value: "01", text: "Progetto Applicativo" } as KeyValue,
        { value: "02", text: "Servizio di Manutenzione" } as KeyValue,
        { value: "03", text: "Progetto Infrastruttura" } as KeyValue,
    ];

    Periodo: KeyValue[] = [
        { value: "01", text: "Giornaliero" } as KeyValue,
        { value: "02", text: "Settimanale" } as KeyValue,
        { value: "03", text: "Mensile" } as KeyValue,
        { value: "0", text: "Altro" } as KeyValue,
    ];

    TipologiaReport: KeyValue[] = [
        { value: "01", text: "Report Proge" } as KeyValue,
        { value: "02", text: "Altro" } as KeyValue,
    ];

    SolutionProductTypology: KeyValue[] = [
        { value: "01", text: "Soluzione" } as KeyValue,
        { value: "02", text: "Progetto" } as KeyValue,
    ];

    get getTIPOLOGIAOFFERTA(): Array<string> {
        return this.TipologiaOfferta.map(a => a.text);
    }
    //Risorse umane drop down
    get getNOMERISORSA(): Array<string> {
        const x = [] as Array<string>;
        this.PokemonList.forEach(element => {
            x.push(element.Name);
        });
        return x;
    }

    get getTIPOLOGIECOMPONENTE(): Array<string> {
        return this.TipoComponente.map(a => a.text);
    }
    get getBUSINESSLINE(): Array<string> {
        return this.BusinessLine.map((s: any) => (({ Text: s.text, Value: s.value + ' - ' + s.text } as unknown as string)))
    }
    get getTECHAREA() {
        const that = this.TechArea;
        return function (id: string) {
            return that.filter(a => a.partentcode == id).map(a => a.text);
        }
    }

    get getTIPOLOGIAFORNITURA(): Array<string> {
        return this.TipologiaFornitura.map(a => a.text);
    }

    //Costi Postsale
    get getPOSTSALE(): Array<string> {
        return this.TipologiaPostsale.map(a => a.text);
    }
    //costi aggiuntivi presale drop down
    get getNOMERISORSAPRESALE(): Array<string> {
        const x = [] as Array<string>;
        this.PokemonList.forEach(element => {
            x.push(element.Name);
        });
        return x;
    }

    get getTIPOLOGIECOMPONENTEPRESALE(): Array<string> {
        return this.TipoComponente.map(a => a.text);
    }
    get getBUSINESSLINEPRESALE(): Array<string> {
        return this.BusinessLine.map(a => a.text);
    }
    get getTECHAREAPRESALE() {
        const that = this.TechArea;
        return function (id: string) {
            return that.filter(a => a.partentcode == id).map(a => a.text);
        }
    }

    get getTIPOLOGIAFORNITURAPRESALE(): Array<string> {
        return this.TipologiaFornitura.map(a => a.text);
    }

    get getVINCOLI(): Array<string> {
        return this.Vincoli.map(a => a.text);
    }

    get getMol(): Array<string> {
        return this.Mol.map(a => a.text);
    }

    get getSUBVINCOLI(): Array<string> {
        return this.SubVincoli.map(a => a.text);
    }

    get getREFERENZACLIENTE(): Array<string> {
        return this.ReferenzaCliente.map(a => a.text);
    }

    get getDOCUMENTIMARKETING(): Array<string> {
        return this.DocumentiMarketing.map(a => a.text);
    }

    get getTIPOLOGIAVERBALE(): Array<string> {
        return this.TipologiaVerbale.map(a => a.text);
    }

    get getPERIODO(): Array<string> {
        return this.Periodo.map(a => a.text);
    }

    get getTIPOLOGIAREPORT(): Array<string> {
        return this.TipologiaReport.map(a => a.text);
    }

    get getSolutionProductTypology(): Array<string> {
        return this.SolutionProductTypology.map(a => a.text);
    }

    @Mutation
    public SET_POKEMONS(name: Pokeomon[]): void {
        this.PokemonList = name;
    }

    @Action({ rawError: true })
    public async RetrievePokemons() {
        await this.pokemonService.GetPokemons().then(a => {
            this.SET_POKEMONS(a)
        })
    }
}

export const OfferServiceModule = getModule(OfferServiceStore);
